<template>
  <v-data-table
    v-model="selected"
    :loading="!!!items"
    :headers="headers"
    :items="items"
    :items-per-page="10"
    :show-select="!readonly"
    :footer-props="footerProps"
    :options.sync="localOptions"
    :server-items-length="meta.total"
    :sort-by="sortBy"
    sort-desc
  >
    <template v-slot:item.action="{ item }">
      <div class="text-center">
        <v-list-item
          :to="{
            name: 'persons.show',
            params: { id: item.person_id },
          }"
          link
        >
          <v-list-item-title>
            <v-icon class="mr-1" small>mdi-eye</v-icon>
            {{ $t('ITEM.VIEW') }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </template>

    <template v-if="isShowTotalSelected" v-slot:body.append="{ headers }">
      <tr>
        <td :colspan="headers.length">
          {{ $t('NOTIFICATIONS.TOTAL_NUMBER_OF_RECIPIENTS') }}:
          {{ selected.length }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'RecipientsList',

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedValue: {
      type: String,
      default: 'id',
    },
    meta: {
      type: Object,
      default: () => ({
        total: -1,
      }),
    },
    options: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localOptions: {},
      sortBy: 'id',
      footerProps: {
        itemsPerPageOptions: [10, 50, 100],
      },
    };
  },

  computed: {
    selected: {
      get() {
        return this.items.filter((i) =>
          this.value.includes(i[this.selectedValue])
        );
      },
      set(v) {
        const value = v.map((i) => i[this.selectedValue]);

        this.$emit('input', value);
      },
    },
    isShowTotalSelected() {
      return !this.readonly && Boolean(this.items.length);
    },
    params() {
      const options = this.localOptions;

      return {
        page: options.page,
        per_page: options.itemsPerPage,
        sort_by: options.sortBy[options.sortBy.length - 1],
        order_by: options.sortDesc[options.sortDesc.length - 1]
          ? 'desc'
          : 'asc',
      };
    },
    headers() {
      const headers = [
        {
          text: this.$t('PERSONS.ITEM'),
          value: 'full_name',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.BUSINESS_CUSTOMER'),
          value: 'business_customer',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.ITEM'),
          value: 'building_complex',
        },
        {
          text: this.$t('BUILDINGS.ITEM'),
          value: 'building',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.MANAGEMENT_COMPANY'),
          value: 'management_company',
        },
        {
          text: '',
          value: 'action',
          align: 'center',
        },
      ];

      if (this.readonly) {
        headers.splice(-1, 0, {
          text: this.$t('NOTIFICATIONS.DATE_TIME_OF_READING'),
          value: 'reading_date',
        });
      }

      return headers;
    },
  },

  watch: {
    localOptions: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
    options: {
      handler() {
        this.localOptions.page = 1;
        this.refreshData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.$emit('refresh', {
        params: Object.assign({}, this.options, this.params),
      });
    },
  },
};
</script>
