<template>
  <div>
    <v-toolbar>
      <v-toolbar-title
        >{{ $t('ITEM.CREATE') }} {{ $t('NOTIFICATIONS.ITEM') }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn dark color="indigo" @click="$router.go(-1)">
        {{ $t('BUTTON.BACK') }}
      </v-btn>
    </v-toolbar>

    <v-sheet class="pa-5" tile>
      <v-form ref="form" v-model="valid" @submit.prevent="create">
        <v-row>
          <v-col cols="12">
            <v-card class="mt-3">
              <v-card-title class="font-weight-regular">{{
                $t('NOTIFICATIONS.GENERAL_INFORMATION')
              }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <general-information-form
                  v-model="notification"
                  :errors="errors.getAll()"
                  @update-prop="clearErrorByName($event)"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <recipients-filters v-model="notification.filter" />
          </v-col>

          <v-col cols="12">
            <recipients
              v-if="notification.filter"
              class="elevation-1"
              v-model="notification.user_ids"
              :items="users"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="float-right"
              color="primary"
              type="submit"
              :disabled="!isValid"
            >
              {{ $t('BUTTON.SAVE') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { serialize } from 'object-to-formdata';
import errors from '@/mixins/errors';
import { NotificationsService } from '@/services/notifications.service';
import Recipients from '../components/RecipientsList.vue';
import RecipientsFilters from '../components/RecipientsFilters.vue';
import GeneralInformationForm from '../components/forms/GeneralInformationForm.vue';

export default {
  name: 'NotificationCreatePage',

  mixins: [errors],

  components: {
    Recipients,
    RecipientsFilters,
    GeneralInformationForm,
  },

  data: () => ({
    valid: false,
    notification: {
      filter: {},
      user_ids: [],
      management_company_id: null,
    },
    users: [],
  }),

  computed: {
    isValid() {
      return this.valid && this.hasSelectedFilter && this.hasSelectedUsers;
    },

    hasSelectedFilter() {
      return Object.values(this.notification.filter).length;
    },

    hasSelectedUsers() {
      return this.notification.user_ids.length;
    },
  },

  watch: {
    'notification.filter': function (filters) {
      const { management_companies = [] } = filters;

      this.getRecipients(filters);
      this.setManagementCompany(management_companies[0]);
    },
  },

  methods: {
    getRecipients(filters) {
      NotificationsService.getRecipients(filters).then(({ data }) => {
        this.users = data;
        this.notification.user_ids = data.map(({ id }) => id);
      });
    },

    setManagementCompany(v) {
      this.notification.management_company_id = v || null;
    },

    create() {
      const options = { indices: true, booleansAsIntegers: true };
      const data = serialize(this.notification, options);

      NotificationsService.create(data)
        .then(() => {
          this.$router.push({ name: 'notifications' });
        })
        .catch((response) => {
          this.handleErrorsByResponse(response);
        });
    },
  },
};
</script>
