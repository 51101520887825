<template>
  <v-card :disabled="readonly">
    <v-card-title class="font-weight-regular">{{
      $t('NOTIFICATIONS.CHOOSE_RECIPIENTS')
    }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-select
        v-model="managementCompany"
        item-value="id"
        item-text="name"
        :readonly="readonly || edit"
        :rules="rules.management_companies"
        :items="managementCompanies"
        :label="`${$t('BUILDING_COMPLEXES.MANAGEMENT_COMPANY')} *`"
      />

      <v-select
        v-model="localFilters.building_complexes"
        item-value="id"
        item-text="name"
        :items="buildingComplexes"
        :readonly="readonly"
        :label="$t('BUILDING_COMPLEXES.ITEM')"
        multiple
      />

      <v-select
        v-model="localFilters.buildings"
        item-value="id"
        :item-text="(i) => [i.street, i.name].filter(Boolean).join(' — ')"
        :items="buildings"
        :readonly="readonly"
        :label="$t('BUILDINGS.ITEM')"
        multiple
      ></v-select>

      <v-btn
        v-show="!readonly"
        :disabled="disabledFind"
        @click="changeFilters()"
        >{{ $t('BUTTON.FIND') }}</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
import { isEqual } from 'lodash';
import { notEmpty } from '@/rules';
import { NotificationsService } from '@/services/notifications.service';

export default {
  name: 'RecipientsFilters',

  model: {
    prop: 'filters',
    event: 'change-filters',
  },

  props: {
    filters: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    valid: false,
    localFilters: {
      management_companies: [],
      building_complexes: [],
      buildings: [],
    },
    managementCompanies: [],
    buildingComplexes: [],
    buildings: [],
    rules: {
      management_companies: [notEmpty],
    },
  }),

  computed: {
    managementCompany: {
      get() {
        return this.localFilters.management_companies[0] || '';
      },
      set(v) {
        this.localFilters.management_companies = [v];
      },
    },
    disabledFind() {
      return Object.values(this.localFilters).every((i) => i.length === 0);
    },
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler: function (filters) {
        if (isEqual(filters, this.localFilters)) {
          return;
        }

        this.localFilters = {
          ...this.localFilters,
          ...filters,
        };
      },
    },
    managementCompany: 'changeManagementCompany',
    'localFilters.building_complexes': 'changeBuildingsComplexes',
  },

  created() {
    NotificationsService.getManagementCompanies().then(({ data }) => {
      this.managementCompanies = data;

      if (
        data.length === 1 &&
        this.localFilters.management_companies.length === 0
      ) {
        this.localFilters.management_companies = data.map(({ id }) => id);
      }
    });
  },

  methods: {
    changeManagementCompany(company) {
      NotificationsService.getBuildingComplexesByCompany(company).then(
        ({ data }) => {
          this.buildingComplexes = data;
        }
      );
    },

    changeBuildingsComplexes(buildingComplexes) {
      NotificationsService.getBuildings({
        building_complexes: buildingComplexes,
      }).then(({ data }) => {
        this.buildings = data;
      });
    },

    changeFilters() {
      this.$emit('change-filters', { ...this.localFilters });
    },
  },
};
</script>
