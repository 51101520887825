function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"8","xs":"12"}},[_c('v-card',{staticClass:"pa-4",attrs:{"outlined":""}},[_c('language-tabs',{ref:"languageTabs",attrs:{"errors":_vm.errors.translations,"eager":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var translation = ref.translation;
          var index = ref.index;
          var language = ref.language;
          var currentLocale = ref.currentLocale;
          var on = ref.on;
          var rest = objectWithoutProperties( ref, ["translation", "index", "language", "currentLocale", "on"] );
          var other = rest;
return [_c('general-information-languages-form',_vm._g(_vm._b({attrs:{"value":translation,"language-id":language.id,"locale":currentLocale,"optional":language.locale !== 'de',"readonly":_vm.readonly},on:{"update-prop":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.updatePropWrapPrefix.apply(void 0, argsArray.concat( [("translations[" + index + "]")] ))},"input":_vm.emitter}},'general-information-languages-form',other,false),on))]}}]),model:{value:(_vm.form.translations),callback:function ($$v) {_vm.$set(_vm.form, "translations", $$v)},expression:"form.translations"}})],1)],1),_c('v-col',{attrs:{"md":"4","xs":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isShowChooseTime)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('NOTIFICATIONS.NOTIFICATION_WILL_BE_SEND_IMMEDIATELY'))+" "),_c('v-switch',{attrs:{"label":("" + (_vm.$t('NOTIFICATIONS.CHOOSE_DATETIME')))},model:{value:(_vm.chooseTime),callback:function ($$v) {_vm.chooseTime=$$v},expression:"chooseTime"}})],1):_vm._e(),(_vm.isShowDatetimePicker)?_c('datetime-picker',{attrs:{"name":"dispatch_time","value":_vm._f("dateTimeFormat")(_vm.form.dispatch_time),"clearable":!_vm.readonly,"date-picker-props":_vm.datePickerProps,"time-picker-props":_vm.timePickerProps,"disabled":_vm.disabledDatetimePicker,"error-messages":_vm.errors.dispatch_time,"rules":_vm.getRulesByName('dispatch_time'),"label":((_vm.$t('NOTIFICATIONS.SENDING_TIME')) + " *")},on:{"input":_vm.setDispatchTime}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"loading":!_vm.imagePreview,"boilerplate":"","max-width":"500","type":"image"}},[_c('div',[(_vm.imagePreview)?_c('v-img',{attrs:{"src":_vm.imagePreview,"max-width":"500"}}):_vm._e()],1)]),(!_vm.readonly)?_c('v-file-input',{staticClass:"mt-1",attrs:{"accept":"image/*","name":"image","label":_vm.$t('NOTIFICATIONS.IMAGE'),"prepend-icon":"mdi-camera","chips":"","show-size":"","error-messages":_vm.errors.image},on:{"click:clear":_vm.clearImage,"change":_vm.handleFileInput}}):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._t("buttons")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }