var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":!!!_vm.items,"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"show-select":!_vm.readonly,"footer-props":_vm.footerProps,"options":_vm.localOptions,"server-items-length":_vm.meta.total,"sort-by":_vm.sortBy,"sort-desc":""},on:{"update:options":function($event){_vm.localOptions=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-list-item',{attrs:{"to":{
          name: 'persons.show',
          params: { id: item.person_id },
        },"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(_vm.$t('ITEM.VIEW'))+" ")],1)],1)],1)]}},(_vm.isShowTotalSelected)?{key:"body.append",fn:function(ref){
        var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(_vm.$t('NOTIFICATIONS.TOTAL_NUMBER_OF_RECIPIENTS'))+": "+_vm._s(_vm.selected.length)+" ")])])]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }