<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="form.name"
          name="name"
          :counter="65"
          :label="getLabel('ITEM.TITLE')"
          :error-messages="errors.name"
          :readonly="readonly"
          :rules="getRulesByName('name')"
          @input="updateValue('name', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-ckeditor
          v-model="form.description"
          name="description"
          :editor="editor"
          :config="editorConfig"
          :readonly="readonly"
          :error-messages="errors.description"
          :rules="getRulesByName('description')"
          :label="getLabel('NOTIFICATIONS.MAIN_INFORMATION')"
          @input="updateValue('description', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { notEmpty, maxLength, notEmoji } from '@/rules';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/de';
import DecoupledDoc from '@ckeditor/ckeditor5-build-decoupled-document';
import VCkeditor from '@/components/VCkeditor';
import formEditable from '@/mixins/form-editable';

export default {
  name: 'GeneralInformationLanguagesForm',

  mixins: [formEditable],

  components: {
    VCkeditor,
  },

  props: {
    languageId: {
      type: Number,
      required: true,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        name: '',
        description: '',
        language_id: this.languageId,
      },
      editor: DecoupledDoc,
    };
  },

  computed: {
    rules() {
      const rules = {
        name: [maxLength(65), notEmoji],
        description: [],
      };

      if (this.optional) {
        return rules;
      }

      return {
        name: [notEmpty, ...rules.name],
        description: [notEmpty, ...rules.description],
      };
    },

    editorConfig() {
      return {
        removePlugins: [
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'Link',
          'MediaEmbed',
          'BlockQuote',
          'Table',
          'TableToolbar',
        ],
        toolbar: [
          'paragraph',
          '|',
          'fontfamily',
          'fontsize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          '|',
          'alignment',
          '|',
          'numberedList',
          'bulletedList',
          '|',
          'indent',
          'outdent',
          '|',
          'undo',
          'redo',
        ],
      };
    },
  },

  methods: {
    getLabel(label) {
      label = this.$t(label);

      return this.optional ? label : `${label} *`;
    },
  },
};
</script>
