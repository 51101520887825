<template>
  <div>
    <v-row>
      <v-col md="8" xs="12">
        <v-card class="pa-4" outlined>
          <language-tabs
            ref="languageTabs"
            v-model="form.translations"
            :errors="errors.translations"
            #default="{
              translation,
              index,
              language,
              currentLocale,
              on,
              ...other
            }"
            eager
          >
            <general-information-languages-form
              :value="translation"
              :language-id="language.id"
              :locale="currentLocale"
              :optional="language.locale !== 'de'"
              :readonly="readonly"
              v-bind="other"
              v-on="on"
              @update-prop="
                updatePropWrapPrefix(...arguments, `translations[${index}]`)
              "
              @input="emitter"
            />
          </language-tabs>
        </v-card>
      </v-col>
      <v-col md="4" xs="12">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div v-if="isShowChooseTime">
                  {{
                    $t('NOTIFICATIONS.NOTIFICATION_WILL_BE_SEND_IMMEDIATELY')
                  }}
                  <v-switch
                    v-model="chooseTime"
                    :label="`${$t('NOTIFICATIONS.CHOOSE_DATETIME')}`"
                  />
                </div>
                <datetime-picker
                  v-if="isShowDatetimePicker"
                  name="dispatch_time"
                  :value="form.dispatch_time | dateTimeFormat"
                  :clearable="!readonly"
                  :date-picker-props="datePickerProps"
                  :time-picker-props="timePickerProps"
                  :disabled="disabledDatetimePicker"
                  :error-messages="errors.dispatch_time"
                  :rules="getRulesByName('dispatch_time')"
                  :label="`${$t('NOTIFICATIONS.SENDING_TIME')} *`"
                  @input="setDispatchTime"
                />
              </v-col>
              <v-col cols="12">
                <v-skeleton-loader
                  :loading="!imagePreview"
                  boilerplate
                  max-width="500"
                  type="image"
                >
                  <div>
                    <v-img
                      v-if="imagePreview"
                      :src="imagePreview"
                      max-width="500"
                    />
                  </div>
                </v-skeleton-loader>

                <v-file-input
                  v-if="!readonly"
                  class="mt-1"
                  accept="image/*"
                  name="image"
                  :label="$t('NOTIFICATIONS.IMAGE')"
                  prepend-icon="mdi-camera"
                  chips
                  show-size
                  :error-messages="errors.image"
                  @click:clear="clearImage"
                  @change="handleFileInput"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <slot name="buttons" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import formEditable from '@/mixins/form-editable';
import LanguageTabs from '@/components/LanguageTabs.vue';
import DatetimePicker from '@/components/DatetimePicker';
import { notEmpty } from '@/rules';
import { dateTimeFormat } from '@/filters';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  compareDates,
} from '@/helpers/datetime';
import GeneralInformationLanguagesForm from './GeneralInformationLanguagesForm.vue';

export default {
  name: 'GeneralInformationForm',

  components: {
    LanguageTabs,
    DatetimePicker,
    GeneralInformationLanguagesForm,
  },

  filters: {
    dateTimeFormat,
  },

  mixins: [formEditable],

  data() {
    return {
      chooseTime: false,
      imagePreview: '',
      form: {
        image: null,
        image_path: '',
        dispatch_time: null,
      },
      rules: {
        dispatch_time: [notEmpty],
      },
      currentDatetime: DateTime.now(),
    };
  },

  computed: {
    isCreated() {
      return !this.form.id;
    },

    isEdit() {
      return Boolean(this.form.id);
    },

    isPastDispatchTime() {
      if (!this.form.dispatch_time) {
        return false;
      }

      return compareDates(this.form.dispatch_time, Date.now()) === -1;
    },

    isShowChooseTime() {
      return (!this.readonly && !this.form.dispatch_time) || this.chooseTime;
    },

    isShowDatetimePicker() {
      return this.chooseTime || Boolean(this.form.dispatch_time);
    },

    disabledDatetimePicker() {
      return this.readonly || (this.isEdit && this.isPastDispatchTime);
    },

    imageSrc() {
      if (!this.form.image_path) {
        return '';
      }

      const coreApi = this.$config.get('app.coreApi');

      return `${coreApi}/../storage/${this.form.image_path}`;
    },

    minDate() {
      return this.currentDatetime.toFormat(DEFAULT_DATE_FORMAT);
    },

    minTime() {
      return this.currentDatetime.toFormat(DEFAULT_TIME_FORMAT);
    },

    datePickerProps() {
      return {
        firstDayOfWeek: 1,
        min: this.minDate,
      };
    },

    timePickerProps() {
      return {
        format: '24hr',
        min: this.minTime,
      };
    },
  },

  watch: {
    chooseTime(v) {
      if (!v) {
        this.form.dispatch_time = null;
      }
    },

    imageSrc(v) {
      this.imagePreview = v;
    },
  },

  methods: {
    setDispatchTime(v) {
      this.form.dispatch_time = v;
      this.updateValue('dispatch_time', v);
    },

    clearImage() {
      this.form.image = null;
      this.imagePreview = '';
      this.updateValue('image');
    },

    handleFileInput(event) {
      if (!event) {
        return;
      }

      let fileReader = new FileReader();

      fileReader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.updateValue('image');
      };

      fileReader.readAsDataURL(new Blob([event]));

      this.form.image = event;
    },
  },
};
</script>
