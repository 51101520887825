<template>
  <div :class="['v-ckeditor', classes]">
    <v-label
      :color="validationState"
      :for="computedId"
      :disabled="disabled"
      :focused="hasState"
      :light="light"
      :dark="dark"
      >{{ label }}</v-label
    >

    <v-card class="v-ckeditor__editor" :color="validationState" outlined>
      <ckeditor
        :id="computedId"
        ref="editor"
        :value="lazyValue"
        :editor="editor"
        :config="defaultConfig"
        :tag-name="tagName"
        :disabled="disabled"
        :style="editorStyle"
        v-bind="$attrs"
        @ready="onReady"
        @focus="onFocus"
        @blur="onBlur"
        @input="internalValue = $event"
        v-on="inputListeners"
      />
    </v-card>

    <v-messages
      :value="messagesToDisplay"
      :color="validationState"
      class="v-ckeditor__messages mt-2"
    />
  </div>
</template>

<script>
import themeable from 'vuetify/lib/mixins/themeable';
import localable from 'vuetify/lib/mixins/localable';
import validatable from 'vuetify/lib/mixins/validatable';

export default {
  name: 'VCkeditor',

  mixins: [themeable, localable, validatable],

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    editor: {
      type: Function,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    tagName: {
      type: String,
      default: 'div',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
      default: 300,
    },
  },

  computed: {
    computedId() {
      return this.id || `input-${this._uid}`;
    },

    inputListeners: function () {
      return Object.assign({}, this.$listeners, {
        focus: this.onFocus,
        blur: this.onBlur,
      });
    },

    classes() {
      return {
        'v-ckeditor_readonly': this.readonly,
        'v-ckeditor_disabled': this.disabled,
        'v-ckeditor_error': this.hasState,
        ...this.themeClasses,
      };
    },

    editorStyle() {
      return {
        minHeight: `${this.minHeight}px`,
      };
    },

    defaultConfig() {
      return {
        language: this.currentLocale,
        isReadOnly: this.readonly,
        ...this.config,
      };
    },

    messagesToDisplay() {
      if (!this.hasMessages) return [];

      return this.validations
        .map((validation) => {
          if (typeof validation === 'string') return validation;
          const validationResult = validation(this.internalValue);
          return typeof validationResult === 'string' ? validationResult : '';
        })
        .filter((message) => message !== '');
    },
  },

  methods: {
    onReady(editor) {
      editor.isReadOnly = this.readonly;
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    onFocus(e) {
      this.isFocused = true;
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.isFocused = false;
      this.$emit('blur', e);
    },
  },
};
</script>

<style lang="scss">
.v-ckeditor {
  &__editor.error {
    background-color: #fff !important;
  }

  .ck.ck-toolbar {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
}
</style>
