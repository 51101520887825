<template>
  <v-tabs v-model="tab">
    <v-tab
      v-for="(language, index) in languages"
      :key="language.id"
      v-on="$listeners"
    >
      <slot
        name="title"
        :index="index"
        :language="language"
        :has-errors="!!errors[index]"
      >
        <span
          :class="{
            'red--text': !!errors[index],
          }"
        >
          {{ language.name }}
        </span>
      </slot>
    </v-tab>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(language, index) in languages"
        :key="language.id"
        :eager="eager"
      >
        <slot
          :index="index"
          :loading="loading"
          :language="language"
          :currentLocale="lang"
          :translation="translationsLocal[index]"
          :errors="errors[index]"
          :on="getEventsItem(index)"
        >
          {{ language.name }}
        </slot>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { find } from 'lodash';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'LanguageTabs',

  provide() {
    return {
      register: this.addForm,
      unregister: this.removeForm,
    };
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    translations: {
      type: Array,
      required: false,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    eager: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      tab: null,
      forms: [],
      languages: [],
    };
  },

  computed: {
    ...mapState('localization', ['lang']),
    translationsLocal() {
      const translations = [];

      this.languages.forEach((language, index) => {
        translations[index] = find(this.realValue, {
          language_id: language.id,
        });
      });

      return translations;
    },

    realValue() {
      return this.value ? this.value : this.translations;
    },
  },

  mounted() {
    this.retrieveLanguages().then((response) => {
      this.languages = response.data;
    });
  },

  methods: {
    ...mapActions('localization', ['retrieveLanguages']),
    getEventsItem(index) {
      return {
        input: (value) => this.updateValue(index, value),
      };
    },
    updateValue(index, value) {
      const data = this.translationsLocal;
      data[index] = value;
      this.$emit('input', data);
    },
    addForm(component) {
      this.forms.push(component);
    },
    removeForm(component) {
      this.forms = this.forms.filter((i) => i._uid !== component._uid);
    },
  },
};
</script>
